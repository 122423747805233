import { PaginatedData } from "../../models/server-response.model"
import { DASHBOARD_BADGE } from "../actions"

interface InitialState {
  dashboardBadgeData: []
}

const initialState: InitialState = {
  dashboardBadgeData: [],
}

export const dasboardBadgeReducer = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case DASHBOARD_BADGE:
      return {
        ...state,
        dashboardBadgeData: payload,
      }
    default:
      return state
  }
}
