import { PaginatedData } from "../../../models/server-response.model"

interface InitialState {
  client_list: PaginatedData
}

const initialState: InitialState = {
  client_list: {
    data: [],
    page: 0,
    total: 15,
    total_pages: 0,
  },
}

export const clientReducer = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case "GET_CLIENT_LIST":
      return {
        ...state,
        client_list: payload,
      }
    case "EDIT_CLIENT":
      return {
        ...state,
        edit_client: payload,
      }
    default:
      return state
  }
}
