import { PaginatedData } from "../../../../models/server-response.model"
import { EDIT_BLOG_POST, GET_BLOG_CATEGORY_AUTHOR_LIST, GET_BLOG_POST_LIST } from "../../../actions"

interface InitialState {
  blog_post: PaginatedData
}

const initialState: InitialState = {
  blog_post: {
    data: [],
    page: 0,
    total: 15,
    total_pages: 0,
  },
}

export const blogPostReducer = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case GET_BLOG_POST_LIST:
      return {
        ...state,
        blog_post: payload,
      }
    case EDIT_BLOG_POST:
      return {
        ...state,
        edit_blog_post: payload,
      }
    case GET_BLOG_CATEGORY_AUTHOR_LIST:
      return {
        ...state,
        category_auth_list: payload,
      }
    default:
      return state
  }
}
