import { PaginatedData } from "../../../models/server-response.model"

interface InitialState {
  claim_list: PaginatedData
}

const initialState: InitialState = {
  claim_list: {
    data: [],
    page: 0,
    total: 15,
    total_pages: 0,
  },
}

export const claimReducer = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case "GET_CLAIM_LIST":
      return {
        ...state,
        claim_list: payload,
      }
    case "EDIT_CLAIM":
      return {
        ...state,
        edit_feature: payload,
      }
    default:
      return state
  }
}
