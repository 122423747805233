export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT = "LOGOUT"

export const SET_MESSAGE = "SET_MESSAGE"
export const CLEAR_MESSAGE = "CLEAR_MESSAGE"

// Contact Us Type
export const GET_CONTACTUS_LIST = "GET_CONTACTUS_LIST"
export const DELETE_CONTACTUS = "DELETE_CONTACTUS"
export const CONTACTUS_LIST_ERROR = "CONTACTUS_LIST_ERROR"

// HomePage Section
export const GET_BANNER_LIST = "GET_BANNER_LIST"
export const EDIT_BANNER = "EDIT_BANNER"
export const UPDATE_BANNER = "UPDATE_BANNER"

// Features Section
export const GET_CLAIM_LIST = "GET_CLAIM_LIST"
export const EDIT_CLAIM = "EDIT_CLAIM"

// Clients
export const GET_CLIENT_LIST = "GET_CLIENT_LIST"
export const EDIT_CLIENT = "EDIT_CLIENT"

// About Section
export const GET_ABOUT_US_LIST = "GET_ABOUT_US_LIST"
export const EDIT_ABOUT_US = "EDIT_ABOUT_US"

// Pages Section
export const GET_PAGE_CONTENT_LIST = "GET_PAGE_CONTENT_LIST"
export const EDIT_PAGE_CONTENT = "EDIT_PAGE_CONTENT"

// Pages Section
export const GET_STAFF_LIST = "GET_STAFF_LIST"
export const EDIT_STAFF = "EDIT_STAFF"

// Testimonials Section
export const GET_TESTIMONIALS_LIST = "GET_TESTIMONIALS_LIST"
export const EDIT_TESTIMONIALS = "EDIT_TESTIMONIALS"

// Meta Data Section
export const GET_METADATA_LIST = "GET_METADATA_LIST"
export const EDIT_METADATA = "EDIT_METADATA"

// Service Area
export const GET_SERVICE_AREA_LIST = "GET_SERVICE_AREA_LIST"
export const EDIT_SERVICE_AREA = "EDIT_SERVICE_AREA"

// Service Area
export const GET_VIDEO_LIST = "GET_VIDEO_LIST"
export const EDIT_VIDEO = "EDIT_VIDEO"

// Blog Categories
export const GET_BLOG_CATEGORY_LIST = "GET_BLOG_CATEGORY_LIST"
export const EDIT_BLOG_CATEGORY = "EDIT_BLOG_CATEGORY"
export const GET_BLOG_POST_LIST = "GET_BLOG_POST_LIST"
export const EDIT_BLOG_POST = "EDIT_BLOG_POST"
export const GET_BLOG_CATEGORY_AUTHOR_LIST = "GET_BLOG_CATEGORY_AUTHOR_LIST"

// Dashboard Badge
export const DASHBOARD_BADGE = "DASHBOARD_BADGE"

// Faq
export const FAQ = "FAQ"
export const EDIT_FAQ = "EDIT_FAQ"

// Adjuster
export const ADJUSTER = "ADJUSTER"
export const EDIT_ADJUSTER = "EDIT_ADJUSTER"
