import { PaginatedData } from "../../../models/server-response.model"
import { EDIT_SERVICE_AREA, GET_SERVICE_AREA_LIST } from "../../actions"

interface InitialState {
  serviceArea: PaginatedData
}

const initialState: InitialState = {
  serviceArea: {
    data: [],
    page: 0,
    total: 15,
    total_pages: 0,
  },
}

export const serviceAreaReducer = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case GET_SERVICE_AREA_LIST:
      return {
        ...state,
        serviceArea: payload,
      }
    case EDIT_SERVICE_AREA:
      return {
        ...state,
        edit_servicearea: payload,
      }
    default:
      return state
  }
}
