import { combineReducers } from "redux"

import { auth } from "./auth.reducer"
import { alert } from "./alert.reducer"
import { contactUsReducer } from "./contact.reducers"
import { loadingReducer } from "./loading.reducer"
import { homePageReducer } from "./home-page.reducers"
import { claimReducer } from "./home-section/claims.reducer"
import { clientReducer } from "./home-section/client.reducer"
import { pageContentReducer } from "./page-content-sctions/page-content.reducer"
import { staffReducer } from "./staff/staff.reducer"
import { testimonialReducer } from "./testimonial/testimonial.reducer"
import { metaDataReducer } from "./meta-data/meta-data.reducer"
import { serviceAreaReducer } from "./service-area/service-area.reducer"
import { videoReducer } from "./video-section/video.reducer"
import { blogCategoryReducer } from "./blog/category/category.reducer"
import { blogPostReducer } from "./blog/posts/post.reducer"
import { dasboardBadgeReducer } from "./dashboard-badge.reducer"
import { faqReducer } from "./faq/faq.reducer"
import { adjusterReducer } from "./adjuster/adjuster.reducer"

// export * from "./users.reducer";
// export * from "./alert.reducer";

const initialState = {
  sidebarShow: true,
  unfoldable: true,
}

const changeState = (state = initialState, { type, ...rest }: any) => {
  switch (type) {
    case "set":
      return { ...state, ...rest }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  changeState,
  auth,
  alert,
  contact_us: contactUsReducer,
  banner_list: homePageReducer,
  claim_list: claimReducer,
  client_list: clientReducer,
  pageContent: pageContentReducer,
  staff: staffReducer,
  testimonial: testimonialReducer,
  metaData: metaDataReducer,
  serviceArea: serviceAreaReducer,
  video: videoReducer,
  loading: loadingReducer,
  blogCategory: blogCategoryReducer,
  blogPost: blogPostReducer,
  dasboardBadge: dasboardBadgeReducer,
  faq: faqReducer,
  adjuster: adjusterReducer,
})

export default rootReducer
