import React from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { Provider } from 'react-redux'
import store from './admin/redux/redux-store'

import "./admin/assets/scss/style.scss"
import Login from "./admin/views/pages/login/Login"
import ForgotPassword from "./admin/views/pages/forgot-password/forgot-password"
import ResetPassword from "./admin/views/pages/reset-password/reset-password"
import { ToastContainer } from "react-toastify"

// Containers
const DefaultLayout = React.lazy(() => import('./admin/layout/DefaultLayout'))

const Routing = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path="/reset-password/:email" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="login" replace />} />
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
        <ToastContainer position="top-right" newestOnTop />
      </Provider>
    </BrowserRouter>
  )
}
export default Routing
