import { PaginatedData } from "../../../models/server-response.model"
import { EDIT_FAQ, FAQ } from "../../actions"

interface InitialState {
  faq_list: PaginatedData
}

const initialState: InitialState = {
  faq_list: {
    data: [],
    page: 0,
    total: 15,
    total_pages: 0,
  },
}

export const faqReducer = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case FAQ:
      return {
        ...state,
        faq_list: payload,
      }
    case EDIT_FAQ:
      return {
        ...state,
        edit_faq: payload,
      }
    default:
      return state
  }
}
