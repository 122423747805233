import { cilUser } from "@coreui/icons"
import CIcon from "@coreui/icons-react"
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { Dispatch } from "redux"
import { UserLogin } from "../../../CommonTypes"
import { forgotPassword } from "../../../redux"

const ForgotPassword = () => {
  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()
  const initialFormState: UserLogin = {
    email: "",
  }
  const [user, setUser] = useState(initialFormState)
  const [validated, setValidated] = useState(false)
  const handleChange = (e: any) => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value })
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }
    setValidated(true)
    if (user.email) {
      dispatch(forgotPassword(navigate, user))
    }
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm noValidate validated={validated} onSubmit={handleSubmit}>
                    <h3>Forgot Password</h3>
                    <p></p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type="email"
                        placeholder="Email"
                        name="email"
                        feedbackInvalid="Please provide a valid email."
                        required
                        onChange={handleChange}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={7}>
                        <CButton type="submit" color="primary" className="px-4">
                          Send Rest Password Link
                        </CButton>
                      </CCol>
                      <CCol xs={5}>
                        <Link to={"/login"}>Have an account? Login</Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ForgotPassword
