// import { error } from '../actions';
import { userLogin, ForgotPassword, RestPassword } from "../../services"
import { UserLogin } from "../../CommonTypes"
import { Dispatch } from "redux"
import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, SET_MESSAGE } from "./types"
import { NavigateFunction } from "react-router-dom"
import { Notify } from "../../helpers/notify"
import { Loading } from "./loading.action"

export const login =
  (navigate: NavigateFunction, userCredencial: UserLogin) => async (dispatch: Dispatch<any>) => {
    dispatch(Loading(true))
    return userLogin(userCredencial).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        })
        dispatch(Loading(false))
        Notify({ type: "success", message: data.message })
        navigate("/dashboard")
      },
      (error) => {
        const message = error.response && error.response.data && error.response.data.message
        dispatch(Loading(false))
        dispatch({
          type: LOGIN_FAIL,
        })
        if (message) {
          Notify({ type: "error", message: error.response.data.message })
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          })
        } else {
          Notify({ type: "error", message: error.response.data })
        }

        return Promise.reject()
      },
    )
  }

export const forgotPassword =
  (navigate: NavigateFunction, userCredencial: UserLogin) => async (dispatch: Dispatch<any>) => {
    dispatch(Loading(true))
    return ForgotPassword(userCredencial).then(
      (data: any) => {
        Notify({ type: "success", message: data.message })
        dispatch(Loading(false))
        navigate("/login")
      },
      (error: any) => {
        Notify({ type: "error", message: error.response?.data?.message })
        dispatch(Loading(false))
        return Promise.reject()
      },
    )
  }

export const restPassword =
  (navigate: NavigateFunction, userCredencial: UserLogin, email: string) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(Loading(true))
    return RestPassword(userCredencial, email).then(
      (data: any) => {
        if (data.response && data.status) {
          Notify({ type: "success", message: data.message })
          dispatch(Loading(false))
          setTimeout(() => {
            navigate("/login")
          }, 2000)
        }
      },
      (error) => {
        Notify({ type: "error", message: error.response.data })
        dispatch(Loading(false))
        return Promise.reject()
      },
    )
  }

export const Logout = () => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: LOGOUT,
  })
  await localStorage.removeItem("user")
}
