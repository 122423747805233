import { PaginatedData } from "../../../models/server-response.model"
import { EDIT_TESTIMONIALS, GET_TESTIMONIALS_LIST } from "../../actions"

interface InitialState {
  testimonial: PaginatedData
}

const initialState: InitialState = {
  testimonial: {
    data: [],
    page: 0,
    total: 15,
    total_pages: 0,
  },
}

export const testimonialReducer = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case GET_TESTIMONIALS_LIST:
      return {
        ...state,
        testimonial: payload,
      }
    case EDIT_TESTIMONIALS:
      return {
        ...state,
        edit_testimonial: payload,
      }
    default:
      return state
  }
}
