import { PaginatedData } from "../../../models/server-response.model"
import { EDIT_VIDEO, GET_VIDEO_LIST } from "../../actions"

interface InitialState {
  video: PaginatedData
}

const initialState: InitialState = {
  video: {
    data: [],
    page: 0,
    total: 15,
    total_pages: 0,
  },
}

export const videoReducer = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case GET_VIDEO_LIST:
      return {
        ...state,
        video: payload,
      }
    case EDIT_VIDEO:
      return {
        ...state,
        edit_video: payload,
      }
    default:
      return state
  }
}
