import { PaginatedData } from "../../models/server-response.model"

interface InitialState {
  banner_list: PaginatedData
}

const initialState: InitialState = {
  banner_list: {
    data: [],
    page: 0,
    total: 15,
    total_pages: 0,
  },
}

export const homePageReducer = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case "GET_BANNER_LIST":
      return {
        ...state,
        banner_list: payload,
      }
    case "EDIT_BANNER":
      return {
        ...state,
        edit_banner: payload,
      }
    default:
      return state
  }
}
