import axios, { AxiosRequestConfig } from "axios";
import { useSelector } from "react-redux";
export const BASE_URL = process.env.REACT_APP_SERVER_URL as string;

export let AuthHeaders = () => {
    const { user } = useSelector((state: any) => ({ ...state.auth }));
    // return authorization header with jwt token
    /* let user = JSON.parse(localStorage.getItem('user')); */

    if (user && user.token) {
        return { headers: { 'x-auth-token': user.token } } as AxiosRequestConfig;
    } else {
        return { headers: { 'x-auth-token': "" } } as AxiosRequestConfig;
    }
}

// Request for Post Request
export const postRequest = (requestUrl: string, postData: any, parms?: any) => {
    return axios.post(requestUrl, postData);
}

// Request for Get Request
export const GetRequest = async (requestUrl: string) => {
    // const { user } = useSelector((state: any) => ({ ...state.auth }));
    return await axios.get(requestUrl);
}

// Delete for Get Request
export const DeleteRequest = async (requestUrl: string) => {
    return await axios.delete(requestUrl);
}

// Delete for Get Request
export const PutRequest = async (requestUrl: string, data: any) => {
    return await axios.put(requestUrl, data);
}