import { PaginatedData } from "../../../models/server-response.model"
import { EDIT_ADJUSTER, ADJUSTER } from "../../actions"

interface InitialState {
  adjuster_list: PaginatedData
}

const initialState: InitialState = {
  adjuster_list: {
    data: [],
    page: 0,
    total: 15,
    total_pages: 0,
  },
}

export const adjusterReducer = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case ADJUSTER:
      return {
        ...state,
        adjuster_list: payload,
      }
    case EDIT_ADJUSTER:
      return {
        ...state,
        edit_adjuster: payload,
      }
    default:
      return state
  }
}
