import { cilUser } from "@coreui/icons"
import CIcon from "@coreui/icons-react"
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { Dispatch } from "redux"
import { UserLogin } from "../../../CommonTypes"
import { Notify } from "../../../helpers/notify"
import "react-toastify/ReactToastify.min.css"
import { restPassword } from "../../../redux"
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"

const ResetPassword = () => {
  const navigate = useNavigate()
  const { email } = useParams<string>()
  const dispatch: Dispatch<any> = useDispatch()
  const initialFormState: UserLogin = {
    password: "",
    confirm_password: "",
  }
  const [user, setUser] = useState(initialFormState)
  const [validated, setValidated] = useState(false)
  const [isRevealPwd, setIsRevealPwd] = useState<boolean>(false)
  const [isConfRevealPwd, setIsConfRevealPwd] = useState<boolean>(false)

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value })
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }
    setValidated(true)

    if (user.password !== user.confirm_password) {
      Notify({ type: "error", message: `Password and Confirm Password are not the same!` })
      return
      // dispatch(forgotPassword(navigate, user));
    } else {
      dispatch(restPassword(navigate, user, email as string))
    }
  }
  const isShow = (show: boolean, filedType: string) => {
    if (filedType === "password") {
      const status = show !== isRevealPwd
      setIsRevealPwd(status)
    } else {
      const status = show !== isConfRevealPwd
      setIsConfRevealPwd(status)
    }
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={5}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm noValidate validated={validated} onSubmit={handleSubmit}>
                    <h3>Reset Password</h3>
                    <p></p>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type={isRevealPwd ? "text" : "password"}
                        placeholder="New Password"
                        name="password"
                        feedbackInvalid="Please provide a new password."
                        required
                        onChange={handleChange}
                      />
                      <span onClick={() => isShow(true, "password")}>
                        {isRevealPwd ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                      </span>
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type={isConfRevealPwd ? "text" : "password"}
                        placeholder="Confirm New Password"
                        name="confirm_password"
                        feedbackInvalid="Please provide a confirm new password."
                        required
                        onChange={handleChange}
                      />
                      <span onClick={() => isShow(true, "confirm")}>
                        {isConfRevealPwd ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                      </span>
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton type="submit" color="primary" className="px-4">
                          Submit
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
      <ToastContainer position="top-right" newestOnTop />
    </div>
  )
}

export default ResetPassword
