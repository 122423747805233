import { PaginatedData } from "../../../../models/server-response.model"
import { EDIT_BLOG_CATEGORY, GET_BLOG_CATEGORY_LIST } from "../../../actions"

interface InitialState {
  blog_category: PaginatedData
}

const initialState: InitialState = {
  blog_category: {
    data: [],
    page: 0,
    total: 15,
    total_pages: 0,
  },
}

export const blogCategoryReducer = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case GET_BLOG_CATEGORY_LIST:
      return {
        ...state,
        blog_category: payload,
      }
    case EDIT_BLOG_CATEGORY:
      return {
        ...state,
        edit_blog_category: payload,
      }
    default:
      return state
  }
}
