import * as React from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import "./loading.css"
export interface LoadingProps {
    loading: boolean;
}
const Loading: React.FunctionComponent<LoadingProps> = (props: LoadingProps) => {
    const loading = props.loading;
    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
            <Box className={loading ? "loading-box" : ""} sx={{ height: 50 }}>
                {loading && <Fade in={loading}>
                    <CircularProgress />
                </Fade>}
            </Box>
        </Box>
    );
}
export default Loading