import { PaginatedData } from "../../../models/server-response.model"
import { EDIT_STAFF, GET_STAFF_LIST } from "../../actions"

interface InitialState {
  staff: PaginatedData
}

const initialState: InitialState = {
  staff: {
    data: [],
    page: 0,
    total: 15,
    total_pages: 0,
  },
}

export const staffReducer = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case GET_STAFF_LIST:
      return {
        ...state,
        staff: payload,
      }
    case EDIT_STAFF:
      return {
        ...state,
        edit_staff: payload,
      }
    default:
      return state
  }
}
