import { PaginatedData } from "../../../models/server-response.model"
import { EDIT_PAGE_CONTENT, GET_PAGE_CONTENT_LIST } from "../../actions"

interface InitialState {
  page_content_list: PaginatedData
}

const initialState: InitialState = {
  page_content_list: {
    data: [],
    page: 0,
    total: 15,
    total_pages: 0,
  },
}

export const pageContentReducer = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case GET_PAGE_CONTENT_LIST:
      return {
        ...state,
        page_content_list: payload,
      }
    case EDIT_PAGE_CONTENT:
      return {
        ...state,
        edit_page_content: payload,
      }
    default:
      return state
  }
}
