
import { ServerResponse } from "../../models/server-response.model";

enum CountActionKind {
    GET_CONTACTUS_LIST = 'GET_CONTACTUS_LIST'
}

interface InitialState {
    loading: boolean,
}

const initialState: InitialState = {
    loading: false,
}

export const loadingReducer = (state = initialState, action: any) => {
    const { type, isLoading } = action;
    switch (type) {
        case "UPDATE_LOADING":
            return {
                ...state,
                loading: isLoading
            };

        default:
            return state;
    }
}