import axios from "axios"
import { UserDetail, UserLogin } from "../CommonTypes"
import { BASE_URL } from "../helpers/global-function-variables"
import { ServerResponse } from "../models/server-response.model"
const URL = `${BASE_URL}/api/admin`
// user Login
export const userLogin = async (userLogin: UserLogin) => {
  const res: ServerResponse<UserDetail> = (await (axios.post(`${URL}/login`, userLogin))).data;
  if (res.response?.token) {
    localStorage.setItem("user", JSON.stringify(res.response))
  }
  return res;
}

export const ForgotPassword = async (userLogin: UserLogin) => {
  const res: ServerResponse<UserDetail> = (await (axios.post(`${URL}/forgot-password`, userLogin))).data;
  return res;
}

export const RestPassword = async (userLogin: UserLogin, email: string) => {
  const res: ServerResponse<UserDetail> = (await (axios.post(`${URL}/rest-password/${email}`, userLogin))).data;
  return res;
}
