
import { PaginatedData, ServerResponse } from "../../models/server-response.model";

enum CountActionKind {
    GET_CONTACTUS_LIST = 'GET_CONTACTUS_LIST'
}

// An interface for our actions


interface InitialState {
    contact_list: PaginatedData,
}

const initialState: InitialState = {
    contact_list: {
        data: [],
        page: 0,
        total: 15,
        total_pages: 0,
    }
}

export const contactUsReducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case "GET_CONTACTUS_LIST":
            return {
                ...state,
                contact_list: payload
            };

        default:
            return state;
    }
}