import { toast, TypeOptions } from "react-toastify";

export interface ToastProps {
    type: TypeOptions;
    message: string;

}

export const Notify = (props: ToastProps) => {
    // use a random type of notification
    toast(props.message, {
        type: props.type,
        hideProgressBar: false,
        progress: undefined,
        theme: "colored"
    });
};