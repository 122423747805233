import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react"
import { useDispatch } from "react-redux"
import CIcon from "@coreui/icons-react"
import { cilLockLocked, cilUser } from "@coreui/icons"
import { login } from "../../../redux/actions/index"
import { UserLogin } from "../../../CommonTypes"
import { Dispatch } from "redux"
import Loading from "../../../helpers/loading"
import { useSelector } from "react-redux"
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"

const Login = () => {
  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()
  const initialFormState: UserLogin = {
    email: "",
    password: "",
    submitted: false,
  }
  const [user, setUser] = useState(initialFormState)
  const [validated, setValidated] = useState(false)
  const [isRevealPwd, setIsRevealPwd] = useState<boolean>(false)

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value })
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    setUser({ ...user, submitted: true })
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }
    setValidated(true)
    if (user.email && user.password) {
      delete user.submitted
      dispatch(login(navigate, user))
    }
  }
  const { loading } = useSelector((state: any) => ({ ...state.loading }))
  const isShow = (show: boolean) => {
    const status = show !== isRevealPwd
    setIsRevealPwd(status)
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={5}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm noValidate validated={validated} onSubmit={handleSubmit}>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type="email"
                        placeholder="Username"
                        name="email"
                        feedbackInvalid="Please provide a valid email."
                        required
                        onChange={handleChange}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        name="password"
                        type={isRevealPwd ? "text" : "password"}
                        placeholder="Password"
                        autoComplete="current-password"
                        required
                        feedbackInvalid="Please provide a valid password."
                        onChange={handleChange}
                      />
                      <span onClick={() => isShow(true)}>
                        {isRevealPwd ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                      </span>
                      {/*  : <VisibilityOffOutlined />} */}
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton type="submit" color="primary" className="px-4">
                          Login
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <Link to={"/forgot-password"}>Forgot password?</Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
      {/*  <ToastContainer position="top-right" newestOnTop /> */}
      <Loading loading={loading} />
    </div>
  )
}

export default Login
