import { Dispatch } from "redux"

const UdateLoading = (isLoading: boolean) => {
  return {
    type: "UPDATE_LOADING",
    isLoading: isLoading,
  }
}

export const Loading = (isLoading: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(UdateLoading(isLoading))
  }
}
