import { AxiosError, AxiosResponse } from "axios"
import { Dispatch } from "react"
import { DeleteRequest, GetRequest } from "../../helpers/global-function-variables"
import Loading from "../../helpers/loading"
import { Notify } from "../../helpers/notify"
import { ServerResponse } from "../../models/server-response.model"
import { ContactUsState } from "../../views/contact-us/contact-us.model"
import { GET_CONTACTUS_LIST } from "./types"

export const getContactUsList = (pages: ContactUsState) => async (dispatch: Dispatch<any>) => {
  return await GetRequest(
    `/contact-us?page=${pages.page}&per_page=${pages.per_page}&order=${pages.order}&order_by=${pages.orderBy}&search=${pages.search}&start_date=${pages.start_date}&end_date=${pages.end_date}`,
  ).then(
    (contact_list: AxiosResponse<ServerResponse>) => {
      Loading({ loading: true })
      dispatch({
        type: GET_CONTACTUS_LIST,
        payload: contact_list.data.response,
      })
    },
    (error) => {
      console.log(error)
    },
  )
}

export const deleteContactUsRecord = (id: string) => async (dispatch: Dispatch<any>) => {
  return DeleteRequest(`/contact-us/${id}`).then(
    async (res: AxiosResponse<ServerResponse>) => {
      if (res.data.status === true) {
        dispatch(
          getContactUsList({
            page: 1,
            per_page: 15,
            orderBy: "createdAt",
            order: "desc",
            search: "",
            start_date: "",
            end_date: "",
          }),
        )
        Notify({ type: "success", message: res.data.message })
      }
    },
    (error: AxiosError) => {
      Notify({ type: "error", message: error.message })
    },
  )
}
